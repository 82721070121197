.filter-tag {
  background-color: $grey85 !important;
  max-width: 100%;

  .filter-icon {
    margin-right: .5rem;
  }

  .filter-input-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-y: clip;
    overflow-x: clip;
    max-height: 80%;
  }
}
