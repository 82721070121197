@import 'assets/styles/partials/base/variables';

.tab-primary {
  width: 100%;
  height: auto;
  background-color: $dashboard-background;

  >.tab-panel {
    display: flex;
    flex-shrink: 0;
  }

  .tab-list {
    margin: $primary-tab-top-margin 0 0 $primary-tab-left-margin;
    display: flex;
    align-items: flex-end;

    &.is-carousel-active {
      margin: $primary-tab-top-margin 0 0 0;
    }
  }

  .tab-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    &.tab-title {
      height: $tab-title-height;
      padding: .75rem;
      background-color: $grey98;
      color: $light-blue-grey;
      font-family: $family-secondary;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: left;
      border-radius: .5rem .5rem 0 0;
      border: unset;

      &.has-icon {
        padding-right: .25rem;
      }

      .icon:last-child {
        color: $grey85;
        margin: 0;

        &:hover {
          color: $light-blue-grey;
          background-color: $shadow-alpha-5;
          border-radius: 1.5rem;
        }
      }

      &.is-active {
        color: $turquoise;
        background-color: $white;

        .icon:last-child {
          color: $light-blue-grey;

          &:hover {
            color: $turquoise;
          }
        }
      }
    }
  }
}

.context-menu {
  visibility: hidden;
  position: fixed;
  z-index: 10000;

  &.is-visible {
    visibility: visible;
    font-size: 1.1rem;
    font-family: $family-secondary;
    background-color: $white;
    border-radius: 1rem;
    padding: 1rem;
    width: auto;
    height: auto;
    margin: .5rem;
    box-shadow: $context-contenu-shadow;

    &.context-menu-item:hover {
      background-color: $grey81;
    }
  }
}

.tab-secondary {
  width: 100%;
  height: auto;

  >.tab-panel {
    border-bottom: .063rem solid $border;
    display: flex;
    scrollbar-width: thin;
  }

  .tab-list {
    margin: $secondary-tab-margin 0 $secondary-tab-margin 1rem;
    display: flex;
    align-items: flex-end;
  }

  .tab-button {
    cursor: pointer;

    &.tab-title {
      height: $tab-title-height;
      padding: .75rem 1.5rem .374rem;
      color: $light-blue-grey;
      font-family: $family-secondary;
      background-color: $body-background-color;
      font-size: 1rem;
      letter-spacing: .014rem;
      line-height: 1.188rem;
      text-align: left;
      border: unset;

      .underline {
        border-radius: .094rem;
        height: .188rem;
        width: 100%;
        position: relative;
        top: .4rem;

        &.underline-active {
          background-color: $primary;
        }
      }

      &.is-active {
        font-weight: 500;
        color: $grey32;
      }
    }
  }
}

.tab-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: fit-content;
  overflow: hidden;
}

.tab-txt {
  overflow: hidden;
  text-overflow: ellipsis;
}
