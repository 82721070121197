/* @font-face generator */
@mixin font($family, $url, $weight, $style, $font-display: auto) {
  @font-face {
    font-family: $family;
    src: url($url + '.woff2') format('woff2'), url($url + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: $font-display;
  }
}

@mixin icon($icon-name, $color: $text, $size: 100%) {
  mask-image: url('/assets/icons/icons.svg#icon-#{$icon-name}');
  background-color: $color;
  mask-size: $size;
  mask-repeat: no-repeat;
  mask-position: center;
}

/* A small mixin' to export a css map */
@mixin export-css-map($map) {
  @if type-of($map) == map {
    @each $prop, $value in $map {
      @if type-of($value) != map {
        #{inspect($prop)}: #{inspect($value)};
      }

      @else {
        @include export-css-map($value);
      }
    }
  }
}
