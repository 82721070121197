@import 'assets/styles/partials/base/variables';

.modal-phase-choice {
  padding-bottom: 1rem;
  border-bottom: $border-modal;

  .label-input {
    font-weight: 400;
  }
}

.modal {
  .selected-phase {
    outline: $border-modal;
    background-color: $turquoise-transparent;
  }

  .custom-icon {
    width: .9rem;
    height: .9rem;
  }

  .field-label {
    white-space: nowrap;
    flex-basis: none;
  }

  .warning {
    margin-left: 1rem;

    .icon-warning {
      color: $orange-warning;
      display: inline;
      width: .8rem;
      height: .8rem;
    }

    .text-warning {
      display: inline;
      margin-left: .3rem;
      font-size: small;
    }
  }
}
