@import 'assets/styles/partials/base/variables';

.tab-carousel {
  width: $carousel-button-width;
  height: auto;
  color: $light-blue-grey;
  font-family: $family-secondary;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  border-radius: .5rem .5rem 0 0;
  border: unset;
  margin: $primary-tab-top-margin 0  0 $primary-tab-left-margin;
  cursor: pointer;
}

.tab-carousel:disabled {
  color: $grey81;
  cursor: auto;
}

.is-diplayed-right {
  margin: $primary-tab-top-margin $primary-tab-left-margin 0 0;
  justify-self: flex-end;
}

.is-not-displayed {
  display: none;
}

.array-carousel {
  border: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
