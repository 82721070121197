@import 'assets/styles/partials/base/variables';

.head-def {
  font-weight: 300;
}

.short-name-def {
  font-weight: 300;
  font-style: italic;
}

.body-def {
  font-size: small;
}
