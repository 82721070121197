@import 'assets/styles/partials/base/variables';

.table-container {
  display: flex;
  flex-direction: column;
  font-size: .8125rem;
  font-family: $family-secondary;
  overflow: visible;

  .table-header {
    display: flex;
  }

  .is-text-aligned {
    text-align: center;
  }

  .info-header {
    overflow: visible;
    position: relative;
  }

  .list-headers {
    width: 100%;
    display: flex;
  }

  .list-row {
    display: flex;
    align-items: center;

    &:hover,
    &.is-active {
      background-color: $equipment-list-selected;
    }

    &.is-selected {
      outline: .08rem dashed $light-blue-grey;
    }
  }

  .list-header,
  .list-cell {
    padding: .125rem .625rem;

    &.is-checkbox {
      display: flex;
      justify-content: center;
    }
  }

  .list-header-container,
  .list-cell {
    &.is-checkbox {
      width: 10%;
    }
  }

  .list-header-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &:last-child {
      .list-header {
        border-right: 0;
      }
    }

    .list-header {
      &:last-child {
        flex-grow: 0;
      }
    }
  }

  .list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: .0625rem solid $dashboard-border-color;
    border-bottom: .0625rem solid $dashboard-border-color;
    color: $light-blue-grey;
    flex-grow: 1;
    padding: .125rem .625rem;
    overflow: hidden;
    font-weight: 550;

    &.justify-content {
      justify-content: center;
      text-align: center;
    }

    &.filters {
      overflow: visible;
      height: 1.8rem;

      &:not(.location) {
        padding: 0;
      }

      .icon {
        height: inherit;
      }

      input[type='search'] {
        font-size: .8125rem;
        font-family: $family-secondary;
        border: 0;
        width: 100%;
        height: 100%;
        padding: .125rem 1rem .125rem 2rem;
        cursor: pointer;

        &::placeholder {
          font-style: italic;
        }
      }
    }

    .custom-icon {
      width: .75rem;
    }
  }

  .checkbox {
    display: flex;

    .checkbox-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
      border: .0938rem solid $link;
      background-color: $body-background-color;
      border-radius: .125rem;
    }

    .custom-icon {
      display: none;
      width: 1rem;
      color: $link;
    }
  }

  .checkbox-input {
    width: 0;
    height: 0;
    opacity: 0%;

    /* stylelint-disable selector-max-compound-selectors */
    &:checked {
      ~ .checkbox-box {
        .custom-icon {
          display: block;
        }
      }
    }
    /* stylelint-enable selector-max-compound-selectors */
  }

  .list-rows {
    flex-grow: 1;
  }

  .cell-25 {
    width: 25%;
  }

  .list-cell {
    .custom-icon {
      width: 1.25rem;
    }
  }

  .warning {
    .custom-icon {
      width: .8rem;
      height: .8rem;
      position: relative;
      color: $orange-warning;
    }
  }
}

.info-container {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  font-size: .9rem;
  font-weight: 400;
  margin: .5rem;
  padding: 1rem;
  border-radius: 1rem;
  right: 5rem;
}

.info-container,
.map-color-container {
  font-family: $family-secondary;
  background-color: $white;
  box-shadow: $context-contenu-shadow;
}

.smartmeter-hovered {
  background-color: $equipment-list-selected;
}
