@import 'assets/styles/partials/base/variables';

.color-square {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
}

.color-line {
  height: 1rem;
  width: .15rem;
  margin-right: .95rem;
  margin-left: .45rem;
}

.map-color-container {
  position: absolute;
  bottom: 4%;
  z-index: 1;
  right: 1%;
  font-size: .8rem;
  opacity: 80%;
  padding: .5rem;
  border-radius: .5rem;
  font-family: $family-secondary;
  background-color: $white;
  box-shadow: $context-contenu-shadow;
  overflow: hidden;
  white-space: nowrap;

  .color-square {
    width: .8rem;
    height: .8rem;
    margin-right: .5rem;
  }

  .legend,
  .color-square,
  .color-line {
    display: inline-block;
  }
}
