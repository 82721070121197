@import 'assets/styles/partials/base/variables';

.decision-accordion {
  .number-permutations {
    margin-left: 2rem;
  }

  .label {
    font-weight: 500;
  }
}

.rules-critical-criterias {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: $aktiv-grotesk;
  margin: 2rem;
  padding: .2rem;
  margin-top: .5rem;
  overflow-x: auto;
  background-color: $grey98;

  .criteria-box,
  .criteria,
  .criteria-value,
  .title-critical-criterias {
    display: inline-block;
  }

  .criteria {
    margin-right: .1rem;
  }

  .criteria-box {
    margin-left: 2rem;
    color: $light-blue-grey;
  }

  .title-critical-criterias {
    color: $turquoise;
    flex-shrink: 0;
    display: flex;
  }
}
