@import 'assets/styles/partials/base/variables';

.accordion {
  font-family: $aktiv-grotesk;
  justify-content: space-between;
  margin: 1rem;
  border: $border-modal;
  border-radius: .3125rem;

  .accordion-content {
    padding: .5rem;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .accordion-title {
    display: flex;
    justify-content: space-between;
    padding: .5rem 3.5rem .5rem 1.5rem;
    cursor: pointer;
    color: $turquoise-text;
    font-size: 1.375rem;
  }

  .title-icon {
    margin-right: 2rem;
  }

  .icon-chevron {
    color: $blue-grey;
  }
}
