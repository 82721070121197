.is-flex-centered {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.is-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.normal-line-height {
  line-height: 1;
}

.is-size-6bis {
  font-size: .8rem;
}

.mt-6bis {
  margin-top: 2rem;
}

.mr-1bis {
  margin-right: .125rem;
}

.truncate {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  vertical-align: sub;
}

.is-ellipsis {
  text-overflow: ellipsis;
}

.is-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

/* Green to red color scale 4 colors  */
@for $i from 0 through length($colors-green-red-color-scale-4) - 1 {
  .green-red-color-scale4-#{$i} {
    color: #{nth($colors-green-red-color-scale-4, calc($i + 1))};
  }
  .element-colorbar.green-red-color-scale4-#{$i} {
    background: #{nth($colors-green-red-color-scale-4, calc($i + 1))};
  }
}

/* Green to red color scale 5 colors  */
@for $i from 0 through length($colors-green-red-color-scale-5) - 1 {
  .green-red-color-scale5-#{$i} {
    color: #{nth($colors-green-red-color-scale-5, calc($i + 1))};
  }
  .element-colorbar.green-red-color-scale5-#{$i} {
    background: #{nth($colors-green-red-color-scale-5, calc($i + 1))};
  }
}

/* Green to red color scale 3 colors  */
@for $i from 0 through length($colors-green-red-color-scale-3) - 1 {
  .green-red-color-scale3-#{$i} {
    color: #{nth($colors-green-red-color-scale-3, calc($i + 1))};
  }
  .element-colorbar.green-red-color-scale3-#{$i} {
    background: #{nth($colors-green-red-color-scale-3, calc($i + 1))};
  }
}

/* Red to green color scale 3 colors  */
@for $i from 0 through length($colors-red-green-color-scale-3) - 1 {
  .red-green-color-scale3-#{$i} {
    color: #{nth($colors-red-green-color-scale-3, calc($i + 1))};
  }
  .element-colorbar.red-green-color-scale3-#{$i} {
    background: #{nth($colors-red-green-color-scale-3, calc($i + 1))};
  }
}

/* Red color scale 3 colors  */
@for $i from 0 through length($colors-red-color-scale-3) - 1 {
  .red-color-scale3-#{$i} {
    color: #{nth($colors-red-color-scale-3, calc($i + 1))};
  }
  .element-colorbar.red-color-scale3-#{$i} {
    background: #{nth($colors-red-color-scale-3, calc($i + 1))};
  }
}

/* White blue colorscale 5 colors  */
@for $i from 0 through length($white-blue-color-scale-5) - 1 {
  .white-blue-color-scale5-#{$i} {
    color: #{nth($white-blue-color-scale-5, calc($i + 1))};
  }
  .element-colorbar.white-blue-color-scale5-#{$i} {
    background: #{nth($white-blue-color-scale-5, calc($i + 1))};
  }
}

/* Blue colorscale 3 colors  */
@for $i from 0 through length($blue-color-scale-3) - 1 {
  .blue-color-scale3-#{$i} {
    color: #{nth($blue-color-scale-3, calc($i + 1))};
  }
  .element-colorbar.blue-color-scale3-#{$i} {
    background: #{nth($blue-color-scale-3, calc($i + 1))};
  }
}

/* Default color for marker that do not have analytics */
.analytics-default {
  color: $grey75;
}
