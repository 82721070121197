@import 'assets/styles/partials/base/variables';

.equipment-list-container {
  max-height: 50vh;

  &.has-analytics {
    height: calc(100% - 2rem);
  }

  &.has-three-columns {
    .list-header-container {
      width: 45%;
    }

    .list-cell {
      &:not(.is-checkbox) {
        width: 45%;
      }
    }
  }

  .list-headers {
    background-color: $dashboard-background;
  }

  .list-header-container,
  .list-cell {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-checkbox {
      width: 10%;
    }
  }

  &.counters-list {
    .list-header-container,
    .list-cell {
      width: 20%;

      &:first-child {
        width: 40%;
      }
    }
  }
}
