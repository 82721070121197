@import 'assets/styles/partials/base/variables';

.loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &.has-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $modal-overlay;
    z-index: 15;

    &.fullscreen {
      position: fixed;
      background-color: $modal-overlay-dark;
    }
  }

  .loader {
    position: absolute;
    height: 1.75rem;
    width: 1.75rem;
    border: .25rem solid $primary;
    border-radius: 100%;
    border-right-color: transparent;
    border-top-color: transparent;
  }

  .is-loading {
    animation: $loader-spin;
  }
}
