@import 'assets/styles/partials/base/variables';

.header {
  height: $header-height;
  padding: .25rem .75rem;
  background-color: $body-background-color;
  box-shadow: $header-shadow;
  z-index: 3;

  .logo-container {
    min-width: 6rem;
  }

  .logo {
    position: relative;
    top: .15rem;
    width: auto;
    height: 95%;
  }

  .nav-link {
    color: $nav-link;
    font-weight: 400;
  }

  .is-small-icon {
    height: 1.12rem;
    width: 1.12rem;
  }

  .profile-picture {
    width: 1.9rem;
  }

  .dropdown-fixed-height {
    height: 3rem;
  }

  .dropdown {
    border-left: .0625rem solid $dropdown-border-color;
  }

  .language-items {
    display: flex;
    justify-content: space-between;
  }

  /* Overriding bulma's features */
  .navbar-link:not(.is-arrowless)::after {
    border-color: $grey32;
  }

  .dropdown-menu {
    min-width: 8rem;
  }
}

.tag-version {
  white-space: nowrap;
  color: $blue;
  font-weight: 800;
  padding-left: .25rem;
}
