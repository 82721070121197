@import 'assets/styles/partials/base/variables';

.capacity-cell {
  display: grid;

  .cursor {
    z-index: 10;
    border-right: 3px solid $black;
    height: 1.2rem;
  }

  .gauge-container,
  .cursor {
    grid-area: 1 / 1;
    justify-content: flex-start;
  }
}

.impact-table {
  color: $light-blue-grey;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  height: $height-impact-table;

  .filter-input {
    background-color: $white;
    padding: none;
  }

  .is-row-modified {
    outline: .0625rem solid $turquoise;
  }

  .list-headers {
    border-top: .0625rem solid $dashboard-border-color;
  }

  .list-rows {
    overflow-y: auto;
  }

  .list-row {
    font-size: .875rem;
    text-overflow: ellipsis;
  }

  .list-cell {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .small-cell {
    width: 8%;
    min-width: 8%;
    max-width: 100%;
  }

  .medium-cell {
    width: 16%;
    min-width: 16%;
    max-width: 100%;
  }

  .big-cell {
    width: 20%;
    min-width: 20%;
    max-width: 100%;
  }

  .with-icon {
    display: inline-block;

    &.truncate {
      margin-right: .5rem;
    }
  }
}

.column {
  flex-basis: initial;
}
