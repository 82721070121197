@import 'assets/styles/partials/base/variables';

.graph {
  background-color: $white;
  font-family: $family-primary;
  border: .125rem solid $graph-light-grey-1;
  border-radius: .625rem;
  overflow: hidden;
  position: relative;
}

.js-plotly-plot .plotly {
  .modebar {
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .modebar-group {
    padding-left: 0;
  }
}
