@import 'assets/styles/partials/base/variables';

.field-body {
  align-items: center;
}

.unit {
  display: inline-block;
}

.is-field-group {
  .principal-field {
    padding: 0 .25rem  .25rem;
  }
}
