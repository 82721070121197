/* Layout */
.app-container {
  background-color: $dashboard-background;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.is-full-dim {
  height: 100%;
  width: 100%;
}

.is-full-height {
  height: 100%;
}

.one-line-text {
  white-space: nowrap;
}

.is-full-width {
  width: 100%;
}

.is-body {
  height: calc(100% - 3rem);
}

.has-background-body {
  background-color: $body-background-color;
}

.is-scrollable {
  overflow: auto;
  scrollbar-width: thin;
}

.is-not-scrollable {
  overflow: hidden;
}

.is-not-displayed {
  display: none !important;
}

/* Overwriting some bulma features */
.columns {
  margin: 0;
}

.columns,
.container {
  &:not(:last-child) {
    margin-bottom: 0;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  opacity: 100%;
}

.no-background-color {
  input {
    background-color: $white !important;
    border-color: $white !important;
  }
}

/* Tooltip */

.has-tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: $black;
  color: $white;
  text-align: center;
  padding: .5rem;
  border-radius: 6px;
  opacity: 50%;
  position: absolute;
  bottom: 1.3rem;

  &.bottom-tooltip {
    bottom: -2rem;
    width: 15rem;
  }
}

.has-tooltip:hover .tooltip-text {
  visibility: visible;
  z-index: 100;
}

/* Defining scrollbar style */

html {
  overflow: auto;
}

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-track {
  background: $grey98;
}

::-webkit-scrollbar-thumb {
  background-color: $grey81;
  border-radius: 4rem;

  &:hover {
    background-color: $grey50;
  }

  &:active {
    background-color: $grey32;
  }
}
