@import 'assets/styles/partials/base/variables';

.banner-container {
  width: 100%;
  height: $banner-height;
  align-items: center;
  display: flex;

  .banner-title,
  .icon {
    display: inline-block;
  }

  .custom-icon {
    margin-right: 1rem;
  }
}
