/* Icons */
.custom-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.icon {
  &.is-turn {
    transform: rotate(90deg);
  }

  &.is-tiny {
    width: .5rem;
    height: .5rem;
  }
  /* stylelint-disable property-no-vendor-prefix */
  &.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }
  /* stylelint-enable property-no-vendor-prefix */
}
