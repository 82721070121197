@import 'assets/styles/partials/base/variables';

.indicators {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
  z-index: 2;
  overflow-y: scroll;

  .indicator {
    &.container {
      background-color: $white;
      font-family: $family-primary;
      border: .125rem solid $graph-light-grey-1;
      border-radius: .625rem;
      margin: .5rem;
      padding: .5rem;
      width: 15rem;
    }

    .name {
      color: $light-blue-grey;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    .main-value {
      font-size: 3.5rem;
      line-height: 4.188rem;
      font-weight: 700;
      text-align: center;
      height: 4rem;
    }

    .tendency-graph {
      height: 6.5rem;
    }
  }
}
