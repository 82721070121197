@import 'assets/styles/partials/base/variables';

.side-bar {
  display: flex;
  flex-direction: column;
  width: 21.75rem;
  height: 100%;
  background-color: $body-background-color;
  z-index: 1;

  .side-bar-content,
  .side-bar-header {
    border-bottom: .0625rem solid $grey85;
  }

  .side-bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
  }

  .close-button {
    color: $light-blue-grey;

    &:hover {
      color: $blue-grey;
      background-color: transparent;
    }

    .custom-icon {
      width: 1rem;
    }
  }
}
