/* Profile Picture */
.picture-wrapper {
  width: 100%;
  position: relative;
  height: 0;
  padding-top: 100%;
}

.picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  border-radius: 100%;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
