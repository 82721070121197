@import 'assets/styles/partials/base/variables';

.login-form {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  gap: 3rem;
  text-align: center;
  padding: 0 4rem;

  .logo {
    margin-left: 20px;
  }

  .login-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    padding: 1rem 0;
  }

  .login-welcome-phrase {
    line-height: 1.375rem;
    font-weight: 700;
    font-size: 2rem;
    color: $grey20;
    font-family: $aktiv-grotesk;
  }

  .login-subtitle {
    font-weight: 600;
    font-size: 1.2rem;
    color: $light-blue-grey;
  }

  .login-button {
    background-color: $primary;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 1.75rem;
    width: 12.5rem;
    height: 3rem;
  }
}
