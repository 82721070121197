/* Fonts declarations */

/* Quicksand */
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandLight', 300, normal);
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandLightOblique', 300, italic);
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandBook', 400, normal);
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandBookOblique', 400, italic);
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandBold', 700, normal);
@include font('Quicksand', '/assets/fonts/quicksand/QuicksandBoldOblique', 700, italic);

/* Aktiv Grotesk */
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-Light', 300, normal);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-LightItalic', 300, italic);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-Regular', 400, normal);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-Italic', 400, italic);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-Medium', 500, normal);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-MediumItalic', 500, italic);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-Bold', 700, normal);
@include font('AktivGrotesk', '/assets/fonts/aktiv-grotesk/AktivGroteskCorp-BoldItalic', 700, italic);
