@import 'assets/styles/partials/base/variables';

.info-icon-default {
  color: $info-icon-default-color;
  transition: color $info-fade;
}

.info-icon-blink {
  color: $info-icon-blink-color;
  transition: color $info-fade;
}

.container-icon {
  display: flex;
  padding-left: .75rem;
  position: relative;
}

.info-dropdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: .5rem;
  width: 32rem;
  margin: 0 auto;

  .field-label {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-small {
      padding-top: 0;
      font-weight: 700;
      padding-right: 12px;
      max-width: fit-content;
    }
  }

  div.field-body .select {
    height: 40px;
    width: 100%;
  }

  div.field-body select {
    height: 40px;
    border-radius: 0;
    background-color: $white;
    width: inherit;
  }
}

.tab-content .info-dropdown {
  border: 1px solid $grey85;
  background-color: $white;
  border-radius: 9999px;
  padding-left: calc(calc(.75em - 1px) + .375em);
  padding-right: calc(calc(.75em - 1px) + .375em);
  height: 40px;
}
