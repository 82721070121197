.tab-content {
  height: calc(100% - 4rem);
  width: 100%;

  .select-dropdown {
    width: 100%;

    select {
      font-size: .8rem;
      width: 100%;
    }

    .select {
      width: 100%;
    }

    .select:not(.is-multiple) {
      height: auto;
    }

    &.field:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}
