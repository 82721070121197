/* Page container for use cases */

.page-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.no-substation-selection {
  display: flex;
  align-items: center;
  text-align: center;
}
